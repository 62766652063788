import type { ReactElement } from 'react'

import type { SimpleImageAsset } from '~/services/media-mapping.server'

export interface PrintableForDownload {
  id: string
  isFreebie?: boolean | null
  title?: string | null
  image?: SimpleImageAsset
}

export type CustomThumbnail = {
  name: string
  url: string
}

export type PrintableDownloadTriggerRenderer = ({
  onClick,
  printable,
  customThumbnail
}: {
  onClick: () => void
  printable: PrintableForDownload
  customThumbnail?: CustomThumbnail
}) => ReactElement

export const DefaultButton: PrintableDownloadTriggerRenderer = ({ onClick, printable, customThumbnail }) => (
  <button
    onClick={onClick}
    className="not-prose align-stretch relative flex aspect-video transform justify-stretch overflow-hidden rounded-lg transition-transform duration-100 hover:scale-105 hover:shadow-md">
    {printable.image?.imageFile?.url ? (
      <img
        src={customThumbnail ? customThumbnail.url : printable.image.imageFile.url}
        alt={customThumbnail ? customThumbnail.name : printable.title || 'Unknown'}
        className="flex-1"
      />
    ) : (
      <div className="flex aspect-video flex-1 items-center justify-center bg-dark text-white dark:bg-white dark:text-dark">
        <p>{printable.title || 'Unknown printable'}</p>
      </div>
    )}
    {printable.isFreebie && (
      <span className="absolute -right-20 top-1 rotate-45 rounded-md bg-pink px-20 py-1 text-xs text-white">FREE</span>
    )}
  </button>
)

export default DefaultButton
