import { printableStates } from '~/config/option-lists'
import type { PrintableDownloads } from '~/routes/api.printables.download.$id'
import useDownloadLogger from '~/hooks/useFileDownload'

import Button from '~/components/Button'
import { BlurrableImage } from '~/components/BlurrableImage'
import type { CustomThumbnail } from './DefaultButton'

export const PrintableFileList = ({
  printable,
  customThumbnail
}: {
  printable: PrintableDownloads
  customThumbnail?: CustomThumbnail
}) => {
  const { logDownload } = useDownloadLogger()
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
      <BlurrableImage
        img={
          <img
            src={customThumbnail ? customThumbnail.url : printable?.image?.imageFile?.url || undefined}
            alt={customThumbnail ? customThumbnail.name : printable.title || 'Unknown'}
            className="h-auto w-full rounded-xl object-cover object-center"
          />
        }
        className="aspect-video rounded-xl"
      />
      <div className="grid auto-rows-min items-start justify-items-stretch gap-4  sm:grid-cols-2 lg:grid-cols-3">
        {printable?.files
          ?.sort((a, b) => a.state.localeCompare(b.state))
          .map(({ file, state, id }) => (
            <Button
              intent="success"
              href={file.url}
              download={`${printable.title}_${state || 'universal'}`}
              key={id}
              newWindow
              text={
                <div className="flex flex-col items-center">
                  <p className="text-lg">{printableStates.find(s => s.value === state)?.label || 'Download'}</p>
                </div>
              }
              onClick={() => logDownload({ fileAssetId: id, printableId: printable.id })}
            />
          ))}
      </div>
    </div>
  )
}

export default PrintableFileList
