import { useFetcher } from '@remix-run/react'

import type { PrintableDownloads } from '~/routes/api.printables.download.$id'

import Banner from '~/components/Banner'
import type { DialogProps } from '~/components/Dialog'
import Dialog from '~/components/Dialog'
import SignUpEmailForm from '~/modules/signup/components/SignUpEmailForm'
import type { PrintableForDownload, PrintableDownloadTriggerRenderer } from './DefaultButton'
import { DefaultButton } from './DefaultButton'
import PrintableFileList from './PrintableFileList'

export type CustomThumbnail = {
  name: string
  urlMd: string
  urlFull: string
}

interface PrintableImageButtonProps extends Omit<DialogProps, 'title' | 'trigger' | 'content'> {
  printable: PrintableForDownload
  buttonComponent?: PrintableDownloadTriggerRenderer
  customThumbnail?: CustomThumbnail
  title?: string
}

const DialogWrapper = ({
  printable,
  buttonComponent = DefaultButton,
  customThumbnail,
  title,
  ...rest
}: PrintableImageButtonProps) => {
  const { data, submit } = useFetcher<PrintableDownloads | { errors: string }>()

  const getPrintableDetails = () => {
    const actionUrl = `/api/printables/download/${printable.id}`

    submit(null, {
      action: actionUrl,
      method: 'get'
    })
  }

  return (
    <Dialog
      title={title || `Download ${printable.title}`}
      className="h-auto overflow-hidden"
      trigger={buttonComponent({
        onClick: getPrintableDetails,
        printable,
        customThumbnail: customThumbnail && {
          name: customThumbnail.name,
          url: customThumbnail.urlMd
        }
      })}
      content={
        data && 'errors' in data ? (
          <div className="mx-auto max-w-md space-y-6">
            <Banner title="Subscription required" intent="info">
              Sign up today to access hundreds of resources and videos!
            </Banner>
            <SignUpEmailForm inOne className="flex flex-col items-stretch space-y-6" />
          </div>
        ) : (
          data &&
          'files' in data && (
            <PrintableFileList
              printable={data as PrintableDownloads}
              customThumbnail={
                customThumbnail && {
                  name: customThumbnail.name,
                  url: customThumbnail.urlFull
                }
              }
            />
          )
        )
      }
      {...rest}
    />
  )
}

export default DialogWrapper
